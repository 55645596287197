
import {Component, Vue} from "vue-property-decorator";
import {DataTableHeader} from 'vuetify';
import {namespace} from "vuex-class";

import {ChargePoint, ChargePointWithLinked} from "@/domain";
import CrudDataTable from "@/components/CrudDataTable.vue";
import Config from "@/domain/config";

const chargePointsModule = namespace('chargePoints');

@Component({
  components: {CrudDataTable},
  data: () => ({
    config: {
      defaultPageSize: 15,
    } as Partial<Config>,
  })
})
export default class ChargePoints extends Vue {

  @chargePointsModule.State('loading')
  readonly loading!: boolean;

  @chargePointsModule.State('items')
  readonly chargePoints!: ChargePointWithLinked[];

  get displayChargePoints(): ChargePointWithLinked[] & { aliases: string }[] {
    return this.chargePoints.map((chargePoint) => {
      return {
        ...chargePoint,
        aliases: chargePoint.linkedCentralSystems
            .filter((lcs) => !!lcs.customChargePointName)
            .map((lcs) => lcs.customChargePointName)
            .join(', '),
      };
    });
  }

  headers: DataTableHeader[] = [
    {text: 'ID', value: 'id', align: 'start'},
    {text: 'Name', value: 'name', align: 'start'},
    {text: 'Aliases', value: 'aliases', align: 'start'},
    {text: 'Network Profile', value: 'networkProfileName', align: 'start'},
    {text: 'Auth status', value: 'basicAuthStatus', align: 'start'},
    {text: 'Type', value: 'type', align: 'start'},
    {text: 'Version', value: 'ocppVersion', align: 'start'},
  ];

  onEditItem(item: ChargePoint) {
    this.$router.push({
      name: 'chargepoints-dialog-edit', params: {
        id: item.id.toString()
      }
    });
  }

  onDeleteItem(item: ChargePoint) {
    this.$router.push({
      name: 'chargepoints-dialog-delete', params: {
        id: item.id.toString()
      }
    });
  }

  onRefresh() {
    this.$store.dispatch("chargePoints/fetchAll");
  }

  mounted() {
    this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.$store.dispatch("chargePoints/fetchAll");
  }
}
